:root {
  --table-size: 100vh;
  --font-size: 1.35rem;
}

html{
  height: 100%;
  display: flex;
  flex-direction: column;
}

body{
  margin: 0;
  background-color: #1a1a1a;
  color: #eee;
}

table{
  border-collapse:collapse;
  border:1px solid #ddd;
  margin: auto;
  width: var(--table-size);
  height: var(--table-size);
  font-size: var(--font-size);
  table-layout: fixed;
  }
  
  table td, table th{
    border:1px solid #ddd;
    text-align: center;
    vertical-align: middle;
  }

table > thead > tr > th{
  font-size: 3.5em;
}
table > tbody > tr > th{
  font-size: 5em;
}
table > tbody > tr > td {
  padding: 4px;
  width: calc(var(--table-size) / 5);
  max-width: calc(var(--table-size) / 5);
  max-height: calc(var(--table-size) / 5);
  overflow: hidden;
}
.bonus{
  background-color: #aaa;
  color:#000;
}
.selected {
  background-color: #00ff00;
  color:#000;
}

.logo{
  font-size: 3rem;
}

.h1{
  font-size: 5rem;
  text-align: center;
  font-weight: bold;
}

.h2{
  font-size: 3.5rem;
  text-align: center;
  font-weight: bold;
}

.border{
  border:1px solid #ddd;
}

.square{
  min-height: 80px;
  text-align: center;
  vertical-align: middle;
  padding: 4px;
}


.circle{
  border-radius: 50%;
  border: 1px solid #000;
  max-height: 80px;
}

.form-control{
  background-color: #ccc;
  color: #000;
}